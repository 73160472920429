/* eslint-disable jsdoc/require-jsdoc */
import React, { Suspense } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { sailthruSignUp } from 'lib/sailthruUtils';
import validEmail from 'lib/validEmail';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { NL_SIGNUP_BUTTON_ANIMATED, EMAIL_PLACEHOLDER_TXT } from 'lib/brandFeatures';
import { InView } from 'react-intersection-observer';

import { Button } from 'components/Button';

import getConfig from 'next/config';
import './styles.themed.scss';

const Recaptcha = React.lazy(() => import('reaptcha'));

const { publicRuntimeConfig: { SAILTHRU_CUSTOMER_ID } } = getConfig();
const block = 'nl-signup-input';

class SignupInput extends React.Component {
  static propTypes = {
    newsletterId: PropTypes.string.isRequired,
    onError: PropTypes.func,
    onSubmit: PropTypes.func,
    renderOnSuccess: PropTypes.node,
    signupSource: PropTypes.string,
    sitekey: PropTypes.string.isRequired,
    vertical: PropTypes.string.isRequired,
    isRail: PropTypes.bool,
    newsletterIdSource: PropTypes.string,
    grayscale: PropTypes.bool,
  };

  static defaultProps = {
    onError: Function.prototype,
    onSubmit: Function.prototype,
    renderOnSuccess: (
      <p>
        {i18next.t('You have been successfully added to our newsletter')}
      </p>
    ),
    signupSource: null,
    isRail: false,
    newsletterIdSource: null,
    grayscale: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      emailInvalid: false,
      submitted: false,
    };
  }

  handleChange = (e) => {
    const { value } = e.target;

    this.setState({ emailAddress: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { emailAddress } = this.state;

    if (validEmail(emailAddress)) {
      this.inputBoxEl.setCustomValidity('');
      this.recaptchaInstance.execute();
      this.setState({ emailInvalid: false });
    } else {
      this.inputBoxEl.setCustomValidity(i18next.t('Invalid email'));
      this.formEl.reportValidity();
      this.setState({ emailInvalid: true });
    }
  };

  captchaVerify = () => {
    const {
      newsletterId,
      signupSource,
      onSubmit,
      onError,
      newsletterIdSource,
    } = this.props;
    const { emailAddress } = this.state;

    const signupValues = {
      id: emailAddress,
      key: 'email',
      email: emailAddress,
      lists: {
        Master: 1,
      },
      vars: {
        [newsletterId]: 1,
        ...(newsletterIdSource && { [newsletterIdSource]: signupSource }),
        sign_up_date: new Date().toISOString(),
        referrer: document?.referrer,
        domain: document?.location.href,
      },
      source: signupSource,
      onSuccess: ({ email }) => {
        this.setState({ submitted: true });
        onSubmit(email);
      },
      onError: () => {
        onError();
      },
    };

    sailthruSignUp(signupValues, SAILTHRU_CUSTOMER_ID);
  };


  render() {
    const {
      renderOnSuccess,
      sitekey,
      isRail,
      grayscale,
      vertical,
    } = this.props;

    const {
      emailAddress,
      emailInvalid,
      submitted,
    } = this.state;

    const placeholderText = i18next.t(getFeatureConfigForBrand(EMAIL_PLACEHOLDER_TXT, vertical));
    const animated = getFeatureConfigForBrand(NL_SIGNUP_BUTTON_ANIMATED, vertical);
    const title = i18next.t('Sign Up');

    return (
      <div className={block}>

        <div className={classNames(`${block}__input-wrapper`, {
          [`${block}__input-wrapper--grayscale`]: grayscale,
          [`${block}__input-wrapper--rail`]: isRail,
        })}
        >
          {!submitted ? (
            <form
              className={classNames(`${block}__form`, {
                [`${block}__form--grayscale`]: grayscale,
                [`${block}__form--rail`]: isRail,
              })}
              ref={(form) => {
                this.formEl = form;
              }}
            >
              <input
                className={classNames(
                  `${block}__input`,
                  {
                    [`${block}__input--grayscale`]: grayscale,
                    [`${block}__input--invalid`]: emailInvalid,
                    [`${block}__input--rail`]: isRail,
                  },
                )}
                name="emailAddress"
                onChange={this.handleChange}
                placeholder={placeholderText}
                ref={(input) => {
                  this.inputBoxEl = input;
                }}
                value={emailAddress}
                required="required"
                aria-required="true"
                type="email"
                minLength="3" // https://stackoverflow.com/a/1423203
              />
              <Button
                id="email-subscribe-button"
                displayType="inline-form-button"
                title={title}
                animated={animated}
                onClick={this.handleSubmit}
                data-confirm_howl="false"
                data-show_prompt="false"
                additionalClasses={classNames(`${block}__button`, {
                  [`${block}__button--grayscale`]: grayscale,
                  [`${block}__button--rail`]: isRail,
                })}
              />
            </form>
          ) : (
            <div
              className={classNames(
                `${block}__submitted-message`,
                { [`${block}__submitted-message--rail`]: isRail },
              )}
            >
              {React.cloneElement(renderOnSuccess)}
            </div>
          )}
        </div>

        <footer className={classNames(`${block}__footer`, {
          [`${block}__footer--grayscale`]: grayscale,
          [`${block}__footer--rail`]: isRail,
        })}
        >
          {i18next.t('This site is protected by recaptcha')}

          &nbsp;
          <span
            className={classNames(
              `${block}__recaptcha-links`,
              { [`${block}__recaptcha-links--rail`]: isRail },
            )}
          >
            <a
              className={classNames(
                `${block}__recaptcha-link`,
                {
                  [`${block}__recaptcha-link--rail`]: isRail,
                },
              )}
              href="https://www.nbcuniversal.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              {i18next.t('Privacy Policy')}
            </a>
            {isRail && ('\u00A0')}
            &nbsp;|&nbsp;
            {isRail && ('\u00A0')}

            <a
              className={classNames(
                `${block}__recaptcha-link`,
                {
                  [`${block}__recaptcha-link--rail`]: isRail,
                },
              )}
              href="https://www.nbcuniversal.com/terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              {i18next.t('Terms of Service')}
            </a>
          </span>
        </footer>
        {sitekey && (
          <InView>
            {({ inView, ref }) => (
              <div ref={ref}>
                {inView && (
                  <Suspense fallback={null}>
                    {/*
                    Component internally handles loading the recaptcha SDK.
                    So if we lazy mount the component when the user scrolls into view
                    we can defer downloading the recaptcha sdk
                  */}
                    <Recaptcha
                      ref={(instance) => {
                        this.recaptchaInstance = instance;
                      }}
                      onVerify={this.captchaVerify}
                      sitekey={sitekey}
                      size="invisible"
                    />
                  </Suspense>
                )}
              </div>
            )}
          </InView>
        )}

      </div>
    );
  }
}

export { SignupInput };
