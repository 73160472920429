import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { decodeString } from 'lib/stringUtils';

import './styles.themed.scss';

const block = 'liftout';

/**
* LiftOut component renders a text lift-out section with optional size and element ID.
*
* @param {object} props - The properties object.
* @param {string} props.elementId - The optional element ID for the lift-out section.
* @param {string} props.size - The size of the lift-out text.
* @param {string} props.text - The text content of the lift-out.
* @returns {React.Element} The rendered LiftOut component.
*/
function LiftOut({
  elementId,
  size,
  text,
}) {
  if (!text) {
    return null;
  }

  return (
    <div
      id={elementId && `anchor-${elementId}`}
      className={classNames(
        block,
        {
          [`${block}--small`]: size === 'small',
          [`${block}--large`]: size === 'large',
        },
      )}
      data-testid="liftout"
    >
      <p className={`${block}__text`}>
        {decodeString(text)}
      </p>
    </div>
  );
}

LiftOut.propTypes = {
  elementId: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  text: PropTypes.string.isRequired,
};

LiftOut.defaultProps = {
  elementId: null,
  size: 'small',
};

export default LiftOut;
